import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import { uploadImg, uploadvideo2 } from "@/api/common";
export default {
  props: {
    /**
     * 类名 classname 用于重置样式
     */
    classname: {
      type: String,
      required: false,
      default: ""
    },
    /**
     * 上传列表的内建样式，支持三种基本样式 text, picture 和 picture-card
     */
    listType: {
      type: String,
      required: false,
      default: "text"
    },
    /**
     * 是否展示 uploadList, 可设为一个对象，用于单独设定 showPreviewIcon 和 showRemoveIcon
     */
    showUploadList: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * 接受一个数组，用于上传列表渲染
     */
    value: {
      type: [Array, String],
      required: false,
      default: function _default() {
        return [];
      }
    },
    /**
     * 最大上传数量
     */
    max: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * 限制上传文件
     */
    fileSize: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * 如果value 为String，并用符号分割，可以用此属性做处理。
     */
    symbol: {
      type: String,
      required: false,
      default: undefined
    },
    // 禁止上传
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // 支持的文件类型 mp4,avi等
    supportFileType: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    // 像素尺寸
    pixelSize: {
      type: String,
      default: function _default() {
        return "";
      }
    },
    // 上传类型
    uploadType: {
      type: Number,
      default: function _default() {
        return 1;
      }
    }
  },
  computed: {
    fileTypeTxt: function fileTypeTxt() {
      return this.uploadType == 2 ? "视频" : "图片";
    }
  },
  data: function data() {
    return {
      fileList: []
    };
  },
  mounted: function mounted() {
    this.init();
    console.log(this.value);
  },
  watch: {
    value: function value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.init();
      }
    }
  },
  methods: {
    previewImg: function previewImg(file) {
      this.$emit("previewImg", file.url, 'video');
    },
    init: function init() {
      var value = this.value,
        symbol = this.symbol;
      // 处理接受数据和分发
      if (Array.isArray(value)) {
        this.initFileList(value);
      } else if (typeof value === "string") {
        if (symbol) {
          this.initFileList(value.split(symbol));
        } else if (value.indexOf(",") > -1) {
          this.initFileList(value.split(","));
        } else {
          // value 是string类型，没有Symbol处理时，为单张图片
          this.fileList = [];
          if (value) {
            this.initFileList([value]);
          }
        }
      }
    },
    initFileList: function initFileList(arr) {
      var _this = this;
      arr.forEach(function (item, index) {
        var temp = {
          uid: index,
          status: "done",
          name: item,
          url: item
        };
        _this.fileList.push(temp);
      });
    },
    beforeUpload: function beforeUpload(file) {
      if (this.supportFileType) {
        var extenstion = this.fileExtension(file.name);
        var isExists = false;
        this.supportFileType.split(",").some(function (ext) {
          if (ext.indexOf(".", ext) != -1) {
            ext = ext.split(".");
            ext = ext.length > 0 ? ext[ext.length - 1] : "";
          }
          if (extenstion.toLowerCase() == ext.toLowerCase()) {
            isExists = true;
            return true;
          }
        });
        if (!isExists) {
          this.toast("\u76EE\u524D\u652F\u6301\u7684\u6587\u4EF6\u7C7B\u578B\u6709:".concat(this.supportFileType), "error");
          return false;
        }
        // console.log(this.supportFileType, file.name, isExists);
      }

      if (this.uploadType == 2) {
        if (!this.isVideo(file.name)) {
          this.toast("请上传正确的视频类型", "error");
          return false;
        }
      } else {
        if (!this.isImage(file.name)) {
          this.toast("请上传图片", "error");
          return false;
        }
      }
      if (this.fileList.length >= this.max) {
        this.toast("你已达到最大上传数量", "error");
        return false;
      }
      if (this.fileSize && file.size / 1024 > this.fileSize) {
        /**
         * 判断上传图片是否超出大小
         *
         * 暂时留白
         */

        this.toast("\u4E0A\u4F20\u7684".concat(this.fileTypeTxt, "\u8D85\u8FC7").concat(this.fileSize / 1024, "M"), "error");
        return false;
      }
      // 上传之前
      file.status = "uploading";
      this.fileList.push(file);
    },
    handleChange: function handleChange(info) {
      // 上传状态修改回调
      if (info.file.status === "removed") {
        this.fileList = info.fileList;
        this.deliveryData();
      }
      if (info.file.status === "done") {
        this.deliveryData();
      } else if (info.file.status === "error") {
        window.console.error("上传失败");
      }
    },
    deliveryData: function deliveryData() {
      var imgList = [];
      this.fileList.forEach(function (item) {
        imgList.push(item.url);
      });
      this.fileList = [];

      /**
       * 如果按字符串处理 则按字符串返回
       */
      if (this.symbol && this.max > 1) {
        imgList = imgList.join(this.symbol);
      }

      /**
       * 返回一个组数
       */
      if (this.max === 1) {
        this.$emit("change", imgList[0]);
        // 一张图片的时候 添加双向绑定V-model
        this.$emit("input", imgList[0]);
      } else {
        this.$emit("input", imgList);
        this.$emit("change", imgList);
      }
    },
    handleUpload: function handleUpload(data) {
      var _data$file = data.file,
        uid = _data$file.uid,
        name = _data$file.name,
        status = _data$file.status;
      var _ = this;
      var temp = {
        uid: uid,
        name: name,
        status: status
      };
      // 图片转base64
      var formData = new FormData();
      formData.append("file", data.file);
      // 上传
      var uploaded;
      if (this.uploadType == 2) {
        uploaded = uploadvideo2(formData);
      } else {
        uploaded = uploadImg(formData);
      }
      uploaded.then(function (res) {
        _.toast("上传成功", "success");
        temp.status = "done";
        temp.url = res;
        _.fileList.splice(_.fileList.length - 1, 1, temp);
        _.handleChange({
          file: temp
        });
      }).catch(function () {
        _.toast("上传失败", "error");
        temp.status = "error";
        _.fileList.splice(_.fileList.length - 1, 1);
        _.handleChange({
          file: temp
        });
      });
    },
    // 是否为图片
    isImage: function isImage(filePath) {
      // 图片后缀
      var types = ["png", "jpg", "jpeg", "gif", "svg", "glb", "gltf"]; // 'tiff','webp','bmp','psd',
      // 文件后缀
      var type = this.fileExtension(filePath);
      // 是否包含
      return types.indexOf(type) !== -1;
    },
    // 是否为音频
    isAudio: function isAudio(filePath) {
      // 后缀
      var types = ["mp3", "wav", "ogg"];
      // 文件后缀
      var type = this.fileExtension(filePath);
      // 是否包含
      return types.indexOf(type) !== -1;
    },
    // 是否为视频
    isVideo: function isVideo(filePath) {
      // 后缀
      var types = ["avi", "wmv", "mpg", "mpeg", "mov", "rm", "ram", "swf", "flv", "mp4", "wma", "avi", "rm", "rmvb", "flv", "mpg", "mkv"]; //['avi', 'wmv', 'mpg', 'mpeg', 'mov', 'rm', 'ram', 'swf', 'flv', 'mp4', 'wma', 'avi', 'rm', 'rmvb', 'flv', 'mpg', 'mkv']
      // 文件后缀
      var type = this.fileExtension(filePath);
      // 是否包含
      return types.indexOf(type) !== -1;
    },
    // 获取文件后缀类型
    fileExtension: function fileExtension(filePath) {
      // 获取最后一个.的位置
      var index = filePath.lastIndexOf(".");
      // 获取后缀
      var type = filePath.substr(index + 1);
      // 返回类型
      return type.toLowerCase();
    }
  }
};