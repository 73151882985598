var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "clearfix",
    class: _vm.classname
  }, [_vm.fileSize ? _c("div", {
    staticClass: "img_size"
  }, [_vm._v("(上传" + _vm._s(_vm.fileTypeTxt) + "不超过" + _vm._s(_vm.fileSize / 1024) + "M)")]) : _vm._e(), _c("a-upload", {
    attrs: {
      listType: _vm.listType,
      beforeUpload: _vm.beforeUpload,
      customRequest: _vm.handleUpload,
      showUploadList: _vm.disabled ? {
        showRemoveIcon: false,
        showPreviewIcon: false
      } : _vm.showUploadList,
      fileList: _vm.fileList,
      disabled: _vm.disabled
    },
    on: {
      change: _vm.handleChange,
      preview: _vm.previewImg
    }
  }, [_vm._t("default", function () {
    return [_vm.fileList.length < _vm.max && _vm.listType === "picture-card" ? _c("div", [_c("a-icon", {
      attrs: {
        type: "plus"
      }
    }), _c("div", {
      staticClass: "ant-upload-text"
    }, [_vm._v("上传" + _vm._s(_vm.fileTypeTxt))])], 1) : _vm._e(), _vm.listType === "text" ? _c("a-button", [_c("a-icon", {
      attrs: {
        type: "upload"
      }
    }), _vm._v("上传" + _vm._s(_vm.fileTypeTxt) + " ")], 1) : _vm._e()];
  })], 2), _vm.supportFileType ? _c("div", {
    staticClass: "support_img_type"
  }, [_vm._v(" 只支持" + _vm._s(_vm.supportFileType) + "格式" + _vm._s(_vm.fileTypeTxt) + " ")]) : _vm._e(), _vm.pixelSize && _vm.uploadType == 1 ? _c("div", {
    staticClass: "pixel_img_type"
  }, [_vm._v(" 只支持" + _vm._s(_vm.pixelSize) + "尺寸图片 ")]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };