import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "请选择类型"
        }]
      }],
      expression: "[\n            'type',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择类型',\n                },\n              ],\n            },\n          ]"
    }],
    staticStyle: {
      width: "262px"
    },
    attrs: {
      placeholder: "请选择",
      size: "large"
    },
    on: {
      change: _vm.onChange
    }
  }, _vm._l(_vm.splashTypeList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), [1, 4, 5, 6].includes(_vm.createForm.getFieldValue("type")) ? _c("a-form-item", {
    attrs: {
      label: "闪屏配置"
    }
  }, [_c("div", [_c("div", {
    staticClass: "phone_name"
  }, [_vm._v("IOS")]), _c("div", {
    staticStyle: {
      display: "flex"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["iosimgurl"],
      expression: "['iosimgurl']"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.isFormDisabled(),
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion1
    },
    on: {
      previewImg: _vm.previewImg
    }
  }), _c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["ios2imgurl"],
      expression: "['ios2imgurl']"
    }],
    staticStyle: {
      width: "200px"
    },
    attrs: {
      disabled: _vm.isFormDisabled(),
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion2,
      showTips: true,
      showUpIcon: true
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1)]), _c("div", {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c("div", {
    staticClass: "phone_name"
  }, [_vm._v("Andriod")]), _c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["androidimgurl"],
      expression: "['androidimgurl']"
    }],
    attrs: {
      disabled: _vm.isFormDisabled(),
      listType: "picture-card",
      max: 1,
      imgProportion: _vm.imgProportion3
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1), _c("div"), _c("span", {
    staticStyle: {
      "font-size": "12px",
      color: "#969696"
    }
  }, [_vm._v("注：IOS和Android可只配置一项")]), _c("p", {
    staticStyle: {
      "font-size": "12px",
      color: "red"
    }
  }, [_vm._v(" 注：图片和视频只需上传其中一种. ")])]) : _vm._e(), [1, 4, 5, 6].includes(_vm.createForm.getFieldValue("type")) ? _c("a-form-item", {
    attrs: {
      label: "视频"
    }
  }, [_c("Upload", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["videourl"],
      expression: "['videourl']"
    }],
    attrs: {
      listType: "picture-card",
      max: 1,
      fileSize: 100 * 1024,
      force: false,
      uploadType: 2,
      supportFileType: ".mp4"
    },
    on: {
      previewImg: _vm.previewImg
    }
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "时间"
    }
  }, [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["time", {
        rules: [{
          required: true,
          message: "请填写时间"
        }]
      }],
      expression: "[\n            'time',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请填写时间',\n                },\n              ],\n            },\n          ]"
    }],
    attrs: {
      "disabled-date": _vm.disabledDate,
      "show-time": {
        hideDisabledOptions: true,
        defaultValue: [_vm.$moment("00:00:00", "HH:mm:ss"), _vm.$moment("23:59:59", "HH:mm:ss")]
      },
      format: "YYYY-MM-DD HH:mm:ss"
    },
    on: {
      change: _vm.onDateChangeTime
    }
  }), _c("span", [_vm._v("（美国时间）")])], 1), _c("a-form-item", {
    attrs: {
      label: "倒计时"
    }
  }, [_c("a-input-number", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["countdown", {
        rules: [{
          required: true,
          message: "请填写倒计时"
        }]
      }],
      expression: "[\n            'countdown',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请填写倒计时',\n                },\n              ],\n            },\n          ]"
    }],
    attrs: {
      min: 0
    }
  }), _c("span", [_vm._v(" s")])], 1), _c("a-form-item", {
    attrs: {
      label: "是否允许跳过"
    }
  }, [_c("a-radio-group", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["isskip", {
        rules: [{
          required: true,
          message: "请选择是否跳过"
        }]
      }],
      expression: "[\n            'isskip',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请选择是否跳过',\n                },\n              ],\n            },\n          ]"
    }]
  }, [_c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("是")]), _c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("否")])], 1)], 1), _vm.createForm.getFieldValue("type") == 1 ? _c("a-form-item", {
    attrs: {
      label: "链接地址"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "请填写链接地址"
        }]
      }],
      expression: "[\n            'content',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请填写链接地址',\n                },\n              ],\n            },\n          ]"
    }]
  })], 1) : _vm._e(), [2, 5, 6].includes(_vm.createForm.getFieldValue("type")) ? _c("a-form-item", {
    attrs: {
      label: _vm.dynamicFromLabel
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["contentid", {
        rules: [{
          required: true,
          message: "\u8BF7\u586B\u5199".concat(_vm.dynamicFromLabel)
        }]
      }],
      expression: "[\n            'contentid',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: `请填写${dynamicFromLabel}`,\n                },\n              ],\n            },\n          ]"
    }],
    attrs: {
      autocomplete: "off"
    }
  })], 1) : _vm._e(), _vm.createForm.getFieldValue("type") == 4 ? _c("a-form-item", {
    attrs: {
      label: "话题名称"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["content", {
        rules: [{
          required: true,
          message: "请输入话题名称"
        }]
      }],
      expression: "[\n            'content',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: '请输入话题名称',\n                },\n              ],\n            },\n          ]"
    }]
  })], 1) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: " "
    }
  }, [_c("a-button", {
    staticClass: "btn_l",
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("取消")]), _c("a-button", {
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)], 1), _c("PreviewMedia", {
    attrs: {
      isShow: _vm.previewVisible && ["video", "audio"].includes(_vm.previewType),
      previewSource: _vm.previewImage,
      mediaType: "video"
    },
    on: {
      closePreview: _vm.previewHandleCancel
    }
  }), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible && _vm.previewType == "pic",
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };